import { mixed } from 'yup'

const FILE_MAX_SIZE = 10 * 1000 * 1000 // 10 Mo

export default function yupFile(options = {}) {
  options.maxSize = options.maxSize || FILE_MAX_SIZE
  options.types = options.types || []
  return mixed()
    .test({
      name: 'fileType',
      message: 'Fichier manquant',
      test: value => {
        if (!value || value?.id) return true
        return value instanceof File
      }
    })
    .test({
      name: 'fileType',
      message: 'Type de fichier incorrect',
      test: value => {
        if (!(value instanceof File)) return true
        return !options.types.length ? true : options.types.includes(value.type)
      }
    })
    .test({
      name: 'fileSize',
      message: `Fichier trop lourd (${options.maxSize / 1000000} Mo maximum)`,
      test: value => {
        if (!(value instanceof File)) return true
        return value.size <= options.maxSize
      }
    })
}
